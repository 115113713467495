.music-player{
    display: flex;
    flex-direction: column;
    margin-top: 10%;
    margin-left: 3%;
}
.play-song-container{
    display: flex;
    justify-content: start;

}
.image-details{
    width: 250px;
    height: 250px;
}
.song-detail{
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    justify-content: start;
}
.song-detail h2{
    margin: 0px;
    padding: 0px;
}
.song-detail p{
    margin: 0px;
    padding: 0px;
}
.playBtn-fvrt{
    display: flex;
    margin-top: 20px;
    gap: 20px;
}
.play-btn{
    padding: 10px 30px;
    border-radius: 20px;
    background-color: #2BC5B4;
    color: white;
    border: none;
    font-size: 1.2rem;
}
.fvrt{
   font-size: 2rem;
}

.related-song-list{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
.list-of-song{
    width: 50%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 5px;
    background-color: white;
    box-shadow: -2px 4px 5px 0px rgba(191,165,165,0.75);
    -webkit-box-shadow: -2px 4px 5px 0px rgba(191,165,165,0.75);
}
.list-of-song:hover{
    background-color: white;
}
.fvrt.fa-solid.fa-heart{
    color: red;
}