.constuction-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
    font-family: "Roboto", sans-serif;
  }
  .constuction-page img{
    border-radius: 10%;
  }
  
  .header-construction {
    font-size: 30px;
  }
  
  .construction-button-container {
    margin-top: 20px;
    font-size: 20px;
  }
  
  .construction-btn {
    cursor: pointer;
    border: none;
    padding: 5px;
    border-radius: 10px;
    background-color: rgb(237, 237, 21);
    font-size: 16px;
  }
  .construction-btn:hover {
    background-color: yellowgreen;
  }