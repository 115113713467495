
.App {
  text-align: center;
}
body{
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Lato',sans-serif;

}
@media (max-width: 975px){
  body{
    overflow-x: hidden;
  }
}

