body{
    margin: 0;
    padding: 0;
}
.hero{
    padding: 70px 10px;
    display: flex;
    width: 100%;
}
.left-sideNavbar{
    width: 15%;
    height: 500x;
}
.main{
    width: 85%;
    display: flex;
    flex-direction: column;
    text-align: start;
    padding-left: 10px;
}
.navbarForMobile{
    display: none;
}
@media (min-width: 976px) and (max-width: 1080px){
    .left-sideNavbar{
        display: none;
    }
}

@media (max-width: 975px){
    .left-sideNavbar{
        display: none;
    }
    .navbarForMobile{
        display: block;
    }

}
