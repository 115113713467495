.login-container{
    width: 100%;
    height: 100vh;
    display: flex;
    text-align: start;
}
.left-login{
    background-color: #8E2225;
    width: 50%;
    height: 100%;
    position: relative;
}
.logo-container{
    display: flex;
    justify-content: start;
    padding: 20px;
}
.logo-img{
    height: 5%;
    display: inline;
    width: 32px;
    height: 32px;
}
.logo-name{
    margin: 0;
    padding-top: 4px;
    color: white;
    font-size: 1rem;
    font-weight: 700;
    padding-left: 2px;

}
.avatar-container{
    position: absolute;
    left: 20%;
    top: 15%;
}
.avatar{
    height: 350px;
    width: 350px;
    border-radius: 50%;
}
.avatar-text{
    text-align: center;
    color: white;
    font-size: 34px;
    margin-bottom: 0px;
}
.avatar-text-style{
    padding: 0px;
    margin: 0px;
    text-align: center;
    font-size: 24px;
    color:#F83A2C;
    font-weight: 600;
    font-family: italic;
}
.right-login{
    width: 50%;
}

@media (max-width: 975px){
    .left-login{
        display: none;
    }
   

}


