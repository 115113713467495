body{
    background-color: #f6f6f6;
    margin: 0px;
   
}

.navbar{
    width: 100%;
    height: 45px;
    display: flex;
    border-bottom: 1px solid rgb(228,222,222);
    padding: 10px 0;
    position: fixed;
    top: 0px;
    background-color: #f6f6f6;
    z-index: 9999;
    justify-content: space-between;
    

}


 .left-nav{
    display: flex;
    height: 100%;
    width: 30%;
    align-items: center;
    justify-content: start;
    white-space: nowrap;
    padding: 0px 1rem;

 }
 .left-nav .logo{
  margin-right: 20px;

 }
.left-nav img{
    height: 60%;
    padding-top: 5px;
}
.nav-links{
  display: flex;
  gap: 10px;
}

.link{
    text-decoration: none;
    color: #3E3E3E;
    font-weight: 600;
   
    /* padding-left: 10px; */
}
 .search-bar{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(216,210,210);
    border-radius: 25px;
    width: 45%;
    height: 60%;
    background-color: white;
    padding: 5px;
    gap: 10px;
    margin-top: 0.2rem;
    
    
    
 }
 .search-bar >input{
    width: 100%;
    background-color: transparent;
    border: none;
   
    
 }
 .search-bar >input:focus{
  outline: none;
 }
 .search-bar .searchIcon{
  color: gray;
  
  
 }
 

 .right-nav{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 25%;
    margin-left: 8%;
    white-space: nowrap;
 }

 .login-signup{
  display: flex;
  gap: 20px;
  padding-right: 2rem;
 }
 .music-mood select{
  color:#3E3E3E;
  border: none;
  background-color: #f6f6f6;
  font-weight: 600;
  font-size: 0.8rem;
 }
 .music-mood select:focus{
 outline: none;
 }

/* Add styling for the user-dropdown container */
.user-dropdown {
    position: relative;
    display: inline-block;
    margin-right: 20px; 
    
  }
  
  .dropdown-toggle {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .dropdown-toggle i{
    color: grey;
  }
  .dropdown-toggle i:first-child{
    background-color: gainsboro;
    color: white;
    padding: 6px 7px;
    border-radius: 50%;
  }
  
  .dropdown-toggle i:last-child {
    margin-left: 8px; 
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: black;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    right: 0;
  }
  
  .dropdown-item-user {
    padding: 10px 15px;
    cursor: pointer;
    color: white;
  }
  .dropdown-item-user:hover{
    background-color:darkblue;
  }
  
  .user-dropdown:hover .dropdown-content {
    display: block; 
  }
  @media (min-width: 976px) and (max-width: 1080px){
   
    .login-signup .signup{
      display: none;
    }
    .login-signup .login{
      padding-right: 1rem;
    }
  }

  @media (max-width: 975px){
    .navbar{
      justify-content: space-between;
    
    }
    
    .nav-links{
      display: none;
    }

  
    .search-bar .searchIcon{
      display: none;
    }
   
    .music-mood{
      padding-right: 1rem;
    }
    .user-dropdown{
      display: none;

    }
    .login-signup {
      display: none;
      
    }
    

}
  



  
  