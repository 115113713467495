.song-category{
    margin-top: 30px;
    margin-right: 30px;
}

 .top-page-links{
     display: flex;
     gap: 40px;

    
 }

 .link {
    border-bottom: 1px solid transparent;
    font-size: 0.8rem;
  }
  
  .link.active {  
    border-color: black; 
    padding-bottom: 0.5rem;
  }
  